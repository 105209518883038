export default {
  "link@about": "Մեր մասին",
  "link@philosophy": "Մեր փիլիսոփայությունը",
  "link@regulation": "Կարգավորում",
  "link@news": "Լուրեր",
  "link@industries": "Ոլորտներ",
  "link@contacts": "Կապ",
  "link@login": "Մուտք",

  "component@action-button@read-more": "Կարդալ ավելին",
  "component@action-button@see-all": "Տեսնել բոլորը",

  "footer@copyright": "Բոլոր իրավունքները պաշտպանված են:",

  "contact@name": "Անուն",
  "contact@name-error": "Անվան դաշտը պարտադիր է:",
  "contact@email": "էլ. հասցե",
  "contact@email-error": "Էլ. հասցեի դաշտը անվավեր է:",
  "contact@message": "Հաղորդագրություն",
  "contact@message-error": "Հաղորդագրության դաշտը պարտադիր է:",
  "contact@send": "Ուղարկել",
  "contact@toast@success": "Հաղորդագրությունը հաջողությամբ ուղարկվեց:",
  "contact@toast@failure": "Չհաջողվեց ուղարկել հաղորդագրությունը:",

  team: "Թիմ",

  "page@home@hero@title-bold": "Գլոբալ Գաղափարներ",
  "page@home@hero@title-light": "Հստակ Լուծումներ",
  "page@home@hero@text":
    "We think that the success of lorem ipsum dolor is the main key",
  "page@home@about@title-bold": "Berkeley-ի",
  "page@home@about@title-light": "մասին",
  "page@home@about@text":
    "«Բերքլի Քեփիթլ» ՓԲԸ-ն հիմնադրվել է 2018 թ.-ի օգոստոսին: Մենք հանդես ենք գալիս որպես ֆոնդերի կառավարիչ և նպատակ ունենք ստեղծել և կառավարել ոչ հրապարակային բազմապրոֆիլ ներդրումային ֆոնդեր՝ պայմանագրային հիմունքներով:",
  "page@home@philosophy@title": "Մեր փիլիսոփայությունը",
  "page@home@philosophy@text@line-1":
    // "Մեր ընկերությունը կարևորում է մարդուն՝ մարդու կյանքը, ընտանիքը, նրա ապահովությունը, շրջապատը որտեղ նա ապրում է: Սա է պատճառը, որ մեր յուրաքանչյուր գործունեությունում հաշվի ենք առնում մարդու կյանքի ապահովությունը և նրա վրա թողած ազդեցությունը:",
    "Մեր ընկերության համար կարևորագույն արժեքներ են՝ մարդը, նրա կյանքը, ընտանիքը, ապահովությունը, միջավայրը, որտեղ նա ապրում է: Սա է պատճառը, որ յուրաքանչյուր նախագծում մենք հաշվի ենք առնում մարդու կյանքի ապահովությունն ու վերջինիս վրա մեր ազդեցությունը:",
  "page@home@philosophy@text@line-2":
    // "Մեր փիլիսոփայությունը հիմնվում է կյանքի համար կարևոր հիմնական փուլերի՝ առողջության, ապահովության, հարմարավետության վրա: Մենք կարծում ենք, որ մարդու մնացած բոլոր պահանջները ներառված են հենց այս փուլերում:",
    "Մեր փիլիսոփայությունը հիմնվում է կյանքի համար կարևոր հիմնական ոլորտների՝ առողջության, ապահովության և հարմարավետության վրա: Մենք կարծում ենք, որ հենց այս ոլորտներում են ներառված մարդու բոլոր պահանջները:",
  "page@home@philosophy@text@line-3":
    // "Նկարում փորձել ենք պատկերավոր ցույց տալ մեր այս գաղափարը, որտեղ հիմնական փուլերը միահյուսվելով դառնում են մեկ ամբողջություն և կազմում մեր կյանքի անբաժան մասը:",
    "Նկարում փորձել ենք պատկերավոր ցույց տալ մեր այս գաղափարը, որտեղ հիմնական ոլորտները միահյուսվելով դառնում են մեկ ամբողջություն և կազմում մեր կյանքի անբաժան մասը:",

  "page@philosophy@hero@title": "Մեր փիլիսոփայությունը",
  "page@philosophy@hero@text":
    "We think that the success of lorem ipsum dolor is the main key",

  "page@home@philosophy@investment@safety-security@title": "Անվտանգություն",
  "page@home@philosophy@investment@comfort@title": "Հարմարավետություն",
  "page@home@philosophy@investment@health@title": "Առողջություն",
  "page@home@philosophy@investment@health@e@effective-healthcare":
    "Առողջապահություն",
  "page@home@philosophy@investment@health@e@effective-healthcare@hospitals":
    "Հիվանդանոցներ",
  "page@home@philosophy@investment@health@e@effective-healthcare@spa-medical":
    "Սպա եւ բժշկական խնամքի կենտրոններ",
  "page@home@philosophy@investment@health@e@effective-healthcare@rehabilitation":
    "Վերականգնողական կենտրոններ",
  "page@home@philosophy@investment@health@e@effective-healthcare@dental":
    "Ատամնաբուժարաններ",
  "page@home@philosophy@investment@health@e@effective-healthcare@elderly":
    "Ծերանոցներ",
  "page@home@philosophy@investment@health@e@quality":
    "Բարձրակարգ բժշկություն եւ որակյալ բժշկական տեխնոլոգիա",
  "page@home@philosophy@investment@health@e@quality@biotech":
    "Տեխնոլոգիական նորամուծություններ",
  "page@home@philosophy@investment@health@e@quality@health-beauty":
    "Ապրանքներ առողջության եւ գեղեցկության համար",
  "page@home@philosophy@investment@health@e@healthy-food":
    "Առողջ սնունդ եւ օրգանական արտադրանք",
  "page@home@philosophy@investment@health@e@healthy-food@organic-products":
    "Օրգանական արտադրանք",
  "page@home@philosophy@investment@health@e@healthy-food@herbs":
    "Խոտաբույսեր եւ բուսական արտադրանք",
  "page@home@philosophy@investment@health@e@healthy-food@restaurants":
    "Ռեստորաններ",
  "page@home@philosophy@investment@health@e@healthy-food@organic-winner":
    "Գինու Էկոլոգիական արտադրություն եւ ալկոհոլային այլ խմիչքներ",
  "page@home@philosophy@investment@health@e@clean-farming":
    "Նորարար օրգանական գյուղատնտեսություն",
  "page@home@philosophy@investment@health@e@clean-farming@wood":
    "Անտառային տնտեսություն",
  "page@home@philosophy@investment@health@e@clean-farming@energy":
    "Էներգիայի արտադրություն",
  "page@home@philosophy@investment@health@e@lifestyle":
    "Առողջ ապրելակերպ եւ բարեկեցություն",
  "page@home@philosophy@investment@health@e@lifestyle@spa-beauty":
    "Սպա եւ գեղեցկության սրահներ",
  "page@home@philosophy@investment@health@e@lifestyle@sport-resorts":
    "Սպորտային առողջարաններ, մարզասրահներ եւ մարզական կենտրոններ",
  "page@home@philosophy@investment@health@e@lifestyle@sport-events":
    "Սպորտային հավաքներ եւ միջոցառումներ",
  "page@home@philosophy@investment@health@e@lifestyle@active-tourism":
    "Ակտիվ զբոսաշրջություն",
  "page@home@philosophy@investment@safety-security@e@physical":
    "Ֆիզիկական անվտանգություն",
  "page@home@philosophy@investment@safety-security@e@physical@safe-infrastructure":
    "Ենթակառուցվածքներ",
  "page@home@philosophy@investment@safety-security@e@physical@residential-construction":
    "Ապահով կենսապայմաններ",
  "page@home@philosophy@investment@safety-security@e@physical@sustainability":
    "Բնապահպանական անվտանգություն եւ կայունություն",
  "page@home@philosophy@investment@safety-security@e@personal":
    "Անձնական ֆինանսական ապահովություն",
  "page@home@philosophy@investment@safety-security@e@personal@fund-investment":
    "Ֆոնդերի եւ ներդրումների կառավարում",
  "page@home@philosophy@investment@safety-security@e@personal@personal-wealth":
    "Անձնական կարողության կառավարում",
  "page@home@philosophy@investment@safety-security@e@personal@financial-insurance":
    "Ֆինանսական ապահովագրություն",
  "page@home@philosophy@investment@safety-security@e@education": "Կրթություն",
  "page@home@philosophy@investment@safety-security@e@education@youth-sporting":
    "Ֆիզիկական եւ հանրային կրթություն",
  "page@home@philosophy@investment@safety-security@e@education@professional-seminars":
    "Մասնագիտական սեմինարներ, գիտաժողովներ եւ վերապատրաստման դասընթացներ",
  "page@home@philosophy@investment@safety-security@e@education@art-education":
    "Գեղարվեստական կրթություն եւ տաղանդների ընտրություն",
  "page@home@philosophy@investment@comfort@e@leisure":
    "Հանգիստ եւ ազատ ժամանակ",
  "page@home@philosophy@investment@comfort@e@leisure@hotels-casino":
    "Հյուրանոցներ եւ խաղատներ",
  "page@home@philosophy@investment@comfort@e@leisure@resorts": "Առողջարաններ",
  "page@home@philosophy@investment@comfort@e@leisure@sport":
    "Սպորտային եւ ժամանցի կենտրոններ",
  "page@home@philosophy@investment@comfort@e@leisure@parks":
    "Զվարճանքի կենտրոններ",
  "page@home@philosophy@investment@comfort@e@leisure@events": "Միջոցառումներ",
  "page@home@philosophy@investment@comfort@e@infrastructure":
    "Ենթակառուցվածքներ",
  "page@home@philosophy@investment@comfort@e@infrastructure@transportation":
    "Հասարակական տրանսպորտ եւ տեղաշարժ",
  "page@home@philosophy@investment@comfort@e@infrastructure@energy":
    "Էներգիայի կայունություն",
  "page@home@philosophy@investment@comfort@e@infrastructure@clean-environment":
    "Մաքուր միջավայր (թափոնների վերամշակում)",
  "page@home@philosophy@investment@comfort@e@infrastructure@parking-traffic":
    "Հասանելի քաղաքային կայանատեղի եւ հարմարավետ երթեւեկություն",
  "page@home@philosophy@investment@comfort@e@social-comfort":
    "Սոցիալական հարմարավետություն",
  "page@home@philosophy@investment@comfort@e@social-comfort@event-production":
    "Միջոցառումներ, արտադրություն եւ ժամանց",
  "page@home@philosophy@investment@comfort@e@social-comfort@restaurants":
    "Ռեստորաններ, բարեր, ակումբներ եւ երաժշտական միջոցառումներ",
  "page@home@philosophy@investment@comfort@e@living":
    "Կյանքի հարմարավետ պայմաններ",
  "page@home@philosophy@investment@comfort@e@living@innovative":
    "Նորարարական տեխնոլոգիաներ եւ ծառայություններ տան համար",
  "page@home@philosophy@investment@comfort@e@living@shopping-malls":
    "Մատչելի առեւտրի կենտրոններ եւ սննդի կետեր",
  "page@home@philosophy@investment@comfort@e@affordable":
    "Հարմարավետ ապրելակերպ",
  "page@home@philosophy@investment@comfort@e@affordable@residential-housing":
    "Ժամանակակից եւ հարմարավետ բնակարան (քաղաքային եւ մարզային)",
  "page@home@philosophy@investment@comfort@e@business":
    "Բիզնես եւ մասնագիտական միջավայր",
  "page@home@philosophy@investment@comfort@e@business@business-conferencing-centers":
    "Ժամանակակից եւ հասանելի բիզնեսկենտրոններ",
  "page@home@philosophy@investment@comfort@e@business@summit-organization":
    "Գիտաժողովների եւ սեմինարների կազմակերպում",
  "page@home@philosophy@investment@comfort@e@business@marketing-service":
    "Շուկայավարման ծառայություններ",

  "page@home@regulation@title": "Կանոնակարգ",
  "page@home@regulation@text":
    "Հայաստանում ներդրումային ֆոնդերը կարգավորվում են «Ներդրումային ֆոնդերի մասին» ՀՀ օրենքով (այսուհետ `Օրենք), օրենքի այլ կետերով և ՀՀ կենտրոնական բանկի` Հայաստանում ներդրումային ֆոնդերի ոլորտի կարգավորող և վերահսկող մարմնի կողմից տրված մի շարք կանոնակարգերով:",
  "page@home@experience@title": "Փորձառություն",
  "page@home@experience@text":
    "Ֆոնդերի ծրագրերը կարող են լինել ինչպես ֆինանսական, այնպես էլ՝ ներդրումային:",
  "page@home@experience@card1@title": "Ֆոնդերի նկարագիր",
  "page@home@experience@card1@text": "Complex population",
  "page@home@experience@card2@title": "Ներդրումային ծրագիր",
  "page@home@experience@card2@text": "Precision medicine",
  "page@home@experience@card3@title": "Ներդրումային գործընթաց",
  "page@home@experience@card3@text": "Person centric ecosystem",
  "page@home@fund@title-bold": "Դրամային ֆոնդեր",
  "page@home@fund@title-light": "AMD Fund",
  "page@home@fund@text":
    "Հիմք ընդունելով մեր ընկերության նպատակներն ու փիլիսոփայությունը՝ մենք իրականացնում ենք քաղաքային ենթակառուցվածքների բարելավմանն ուղղված ծրագրեր և նպաստում մարդկանց համար հարմարավետ և բարեկեցիկ միջավայր ստեղծելուն:",
  "page@home@news@title-bold": "Մեր",
  "page@home@news@title-light": "Լուրերը",
  "page@home@contact@title-bold": "Գրեք",
  "page@home@contact@title-light": "մեզ",
  "page@home@contact@text":
    "Գրեք մեզ ցանկացած հարցի և առաջարկների դեպքում: Մենք կպատասխանենք հետադարձ նամակով 1 աշԽատանքային օրվա ընթացքում:",

  "page@about-us@hero@title-bold": "Berkeley-ի",
  "page@about-us@hero@title-light": "մասին",
  "page@about-us@hero@text":
    "We think that the success of lorem ipsum dolor is the main key",
  "page@about-us@who-we-are@title-bold": "Ով ենք",
  "page@about-us@who-we-are@title-light": "մենք",
  "page@about-us@who-we-are@line-1":
    "«Բերքլի Քեփիթլ»-ը փակ բաժնետիրական ընկերություն է, որը հիմնադրվել է 2018 թվականի օգոստոսին:",
  "page@about-us@who-we-are@line-2":
    "Մեր աշխատանքային ոլորտները բազմազան են՝ ներառում են առևտրային և բնակելի շինարարության, ենթակառուցվածքների և կոմունալ ծառայությունների, ժամանցի և զվարճությունների, արտադրության, էլեկտրաէներգիայի և այլընտրանքային էներգիայի, կենսատեխնոլոգիաների բնագավառում գիտահետազոտական աշխատանքների և արտադրության ոլորտները:",
  "page@about-us@experience@title": "Փորձառություն",
  "page@about-us@experience@line-1":
    "Ֆոնդային ծրագրերը կարող են լինել ինչպես ֆինանսական, այնպես էլ ներդրումային:",
  "page@about-us@experience@line-2":
    "Մեր բանիմաց ֆինանսական մասնագետների թիմը՝ օժտված լինելով խնդիրներ լուծելու լավ հմտություններով՝ առաջարկում է բիզնեսին ոչ միայն նորագույն ֆինանսական պրոդուկտներ, այլ նաև իրական լուծումներ ու մոտեցումներ, հիմք ընդունելով գործընկերներին բիզնեսում խթանելու նպատակը:",
  "page@about-us@experience@line-3":
    "Մեզ առաջ մղող գաղափարը շահութաբեր բիզնեսի հնարավորությունները վերհանելու և զարգացնելու արդյունքում մեր ներդրողների համար առավելագույն արժեքի ստեղծումն է և նրանց կայուն ֆինանսական բարօրության ապահովումը:",

  "page@news@hero@title-bold": "Լուրեր",
  "page@news@hero@title-light": "",
  "page@news@hero@text":
    "We think that the success of lorem ipsum dolor is the main key",
  "page@news@article@title-bold": "Ամենաշատ կարդացած",
  "page@news@article@title-light": "հոդվածները",
  "page@news@article@show-more": "Ցույց տալ ավելին",
  "page@news@article@sort@title": "Դասավորել ըստ",
  "page@news@article@sort@title-az": "Վերնագիր (Ա-Ֆ)",
  "page@news@article@sort@title-za": "Վերնագիր (Ֆ-Ա)",
  "page@news@article@sort@date-09": "Ամսաթիվ (0-9)",
  "page@news@article@sort@date-90": "Ամսաթիվ (9-0)",

  "page@regulation@hero@title-bold": "Կարգավորում",
  "page@regulation@hero@title-light": "",
  "page@regulation@hero@text":
    "We think that the success of lorem ipsum dolor is the main key",
  "page@regulation@info@text-1":
    // "Հայաստանում ներդրումային ֆոնդերը կարգավորվում են «Ներդրումային ֆոնդերի մասին» ՀՀ օրենքով (այսուհետ `Օրենք), օրենքի այլ կետերով և ՀՀ կենտրոնական բանկի` Հայաստանում ներդրումային ֆոնդերի ոլորտի կարգավորող և վերահսկող մարմնի կողմից տրված մի շարք կանոնակարգերով:",
    "Հայաստանում ներդրումային ֆոնդերը կարգավորվում են «Ներդրումային ֆոնդերի մասին» ՀՀ օրենքով (այսուհետ` Օրենք), այլ օրենքներով և Հայաստանում ներդրումային ֆոնդերի ոլորտը կարգավորող և վերահսկող մարմնի՝ ՀՀ կենտրոնական բանկի կողմից ընդունված մի շարք կանոնակարգերով։",
  "page@regulation@info@text-2":
    // "Օրենքն ընդունվել է 2010 թվականի դեկտեմբերին և ուժի մեջ է մտել 2011 թվականի հունվարից: Օրենքը սահմանում է Հայաստանում ներդրումային ֆոնդերի, ֆոնդերի կառավարիչների գրանցման և գործունեության կարգավորիչ ռեժիմ, ինչպես նաև սահմանում է ներդրումային ֆոնդերի տեսակը և պահանջները յուրաքանչյուր տեսակի համար:",
    "Օրենքն ընդունվել է 2010 թվականի դեկտեմբերին և ուժի մեջ է մտել 2011 թվականի հունվարից: Օրենքը սահմանում է Հայաստանում ներդրումային ֆոնդերի, ֆոնդերի կառավարիչների գրանցման և գործունեության կարգավորիչ ռեժիմը, ինչպես նաև սահմանում է ներդրումային ֆոնդերի տեսակը և պահանջները յուրաքանչյուր տեսակի համար:",
  "page@regulation@info@text-3":
    // "Օրենքը կարգավորում է ինչպես պետական, այնպես էլ մասնավոր/ոչ հանրային միջոցները: Ըստ օրենքի ոչ պետական և փակ ֆոնդերի դեպքում ներդրողների քանակը չպետք է գերազանցի 49-ը: Ֆոնդի բաժնետոմսերը և հասույթը չեն կարող բաժանվել հանրությանը:",
    "Օրենքը կարգավորում է ինչպես հրապարակային, այնպես էլ մասնավոր/ոչ հրապարակային ֆոնդերը: Համաձայն Օրենքի ֆոնդը ոչ հրապարակային դասակարգելու համար տվյալ ֆոնդի ներդրողների քանակը չպետք է գերազանցի 49-ը և ֆոնդի բաժնետոմսերը/փայերը չեն կարող տեղաբաշխվել հրապարակային առաջարկի միջոցով:",
  "page@regulation@info@text-4":
    // "Օրենքը վերաբերում է ոչ պետական միջոցներին միայն շատ սահմանափակ չափով, մասնավորապես կենտրոնանալով գրանցման գործընթացի վրա:",
    "Օրենքի միայն մի քանի դրույթ է վերաբերում ոչ հրապարակային ֆոնդերին։ Օրենքի շրջանակներում հիմնական կարգավորման առարկան ոչ հրապարակային ֆոնդերի գրանցման գործընթացն է։",
  "page@regulation@info@text-5":
    // "Ստորև ներկայացված են ֆոնդերի և դրանց կառավարման մասին պետական օրենքի հղումները:",
    "Ստորև ներկայացված են ֆոնդերին առնչվող կարևոր փաստաթղթերի հղումները․",
  "page@regulation@info@text-6":
    // "Ներդրումային ֆոնդերի մասին օրենք",
    "Ներդրումային ֆոնդերի մասին ՀՀ օրենք",
  "page@regulation@info@text-6-link":
    "https://www.arlis.am/DocumentView.aspx?docid=122453",
  "page@regulation@info@text-7":
    // "Կանոնակարգ «10/29 ոչ հրապարակային պայմանագրային ներդրումային ֆոնդի կանոնների առցանց գրանցման ընթացակարգ»",
    "Կանոնակարգ «10/29 ոչ հրապարակային պայմանագրային ներդրումային ֆոնդի կանոնների առցանց գրանցման ընթացակարգ»",
  "page@regulation@info@text-7-link":
    "https://www.cba.am/AM/laregulations/Regulation_10.29.rar",
  "page@regulation@info@text-8":
    // "Կանոնակարգ 10/04 «Ներդրումային ֆոնդերի զուտ ակտիվների արժեքի հաշվարկման կարգը»",
    "Կանոնակարգ 10/04 «Ներդրումային ֆոնդերի զուտ ակտիվների արժեքի հաշվարկման կարգը»",
  "page@regulation@info@text-8-link":
    "https://www.cba.am/am/SitePages/reginvestment.aspx",
  "page@regulation@info@text-9":
    // "Կանոնակարգ 10/03 «Ներդրումային ֆոնդի կառավարչի կողմից իր և իր կողմից կառավարավող ֆոնդերի մասին Կենտրոնական բանկ ներկայացվող հաշվետվությունները, դրանց ձևերը, բովանդակությունը, ներկայացման կարգը, ժամկետները և պարբերականությունը, կառավարչի կողմից առանձին որոշումների կայացման մասին Կենտրոնական բանկին լրացուցիչ տեղեկությունների տրամադրման կարգը»",
    "Ներդրումային ֆոնդերի վերաբերյալ օրենքների և կանոնակարգերի մասին լրացուցիչ տեղեկությունների համար կարող եք այցելել ՀՀ կենտրոնական բանկի",
  "page@regulation@info@text-10":
    "Ներդրումային ֆոնդերի վերաբերյալ օրենքների և կանոնակարգերի մասին լրացուցիչ տեղեկությունների համար կարող եք այցելել",
  "page@regulation@info@text-10-link":
    "https://www.cba.am/en/SitePages/lawsinvestmentmain.aspx",
  "page@regulation@info@text-10-link-title": "պաշտոնական կայքը",

  "page@industries@hero@title-bold": "Ոլորտներ",
  "page@industries@hero@title-light": "",
  "page@industries@hero@text":
    "We think that the success of lorem ipsum dolor is the main key",
  "page@industries@info@title": "Նախագծեր",
  "page@industries@info@line-1":
    // "Հիմք ընդունելով մեր ընկերության նպատակներն ու փիլիսոփայությունը՝ մենք իրականացնում ենք քաղաքային ենթակառուցվածքների բարելավմանն ուղղված ծրագրեր և նպաստում մարդկանց համար հարմարավետ և բարեկեցիկ միջավայր ստեղծելուն:",
    "Հիմք ընդունելով մեր ընկերության նպատակներն ու փիլիսոփայությունը՝ մենք իրականացնում ենք քաղաքային ենթակառուցվածքների բարելավմանն ուղղված ծրագրեր և նպաստում մարդկանց համար հարմարավետ և բարեկեցիկ միջավայր ստեղծելուն:",
  "page@industries@info@line-2":
    // "Մարդու՝ անհատի, բարօրությանն ուղղված ներդրումների համար, մեր նպատակն է աշխատել և՛ ֆիզիկական, և՛ մարդկային ռեսուրսների ոլորտի հետ:",
    "Մենք նպատակ ունենք աշխատելու ոչ միայն ֆիզիկական ռեսուրսների ոլորտում, այլ նաև բարենպաստ պայմաններ ստեղծել մարդու բարօրությանն ուղղված ներդրումների համար, այդ թվում ՝ որակյալ ժամանց, մարդկանց շարժունակություն, սպորտային հանգստի խթանում և մարդու առողջության որակի բարձրացում:",
  "page@industries@strategy@title-1": "Ներդրումային ծրագրերի",
  "page@industries@strategy@title-2": "հետաքրքրությունների շրջանակ",
  "page@industries@strategy@text-top":
    "Մեր ֆոնդերի շրջանակը ներառում է հետևյալ ոլորտները՝",
  "page@industries@strategy@item-1": "Շինարարություն",
  "page@industries@strategy@item-2": "Ենթակառուցվածքներ",
  "page@industries@strategy@item-3": "Ժամանց, զվարճություններ և տուրիզմ",
  "page@industries@strategy@item-4": "Արտադրություն",
  "page@industries@strategy@item-5": "Էներգետիկ ոլորտ",
  "page@industries@strategy@item-6": "Կենսատեխնոլոգիաներ",
  "page@industries@strategy@text-bottom":
    "Ընկերությունը որպես իր կողմից իրականացնող ներդրումային ծրագրերի ֆինանսավորման աղբյուր դիտարկում է «ներդրողների փակ ակումբ»-ում ընդգրկված մասնակիցների միջոցները:",

  "page@contact@hero@title-bold": "Կապ",
  "page@contact@hero@title-light": "մեզ հետ",
  "page@contact@hero@text": "Գրեք մեզ ցանկացած հարցի և առաջարկների դեպքում:",
  "page@contact@title@email": "Էլ հասցե",
  "page@contact@title@phone": "Հեռախոսահամար",
  "page@contact@title@address": "Հասցե",
  "page@contact@title@address-value-1": "ՀՀ. 0023 ք. Երևան",
  "page@contact@title@address-value-2": "Բրյուսովի փ. 6շ.",
  // "page@contact@title-bold": "Ուղարկել",
  // "page@contact@title-light": "հաղորդագրություն",
  // "page@contact@text":
  //   "Մենք համագործակցում ենք առաջատար առողջապահության, խոշոր մասնավոր վենչուրային կապիտալի հետ:",
  "page@contact@title-bold": "Գրեք",
  "page@contact@title-light": "մեզ",
  "page@contact@text": "Ցանկացած հարցի և առաջարկների դեպքում:",
};
