import React, { useState, useEffect, useCallback } from "react";
import { RouteLink } from "../../components/RouteLink";
import { Box, Text } from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { locales, useTranslation } from "../../core/hooks/useTranslation";
import NavbarBox from "./NavbarBox";
import links from "../../links";
import {
  AuthenticatedTemplate,
  useMsal,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

const setScrollable = (scrollable) => {
  if ("undefined" !== typeof window && "undefined" !== typeof document) {
    document.body.style.overflow = !scrollable ? "hidden" : "";
  }
};

function MobileMenu({ smallHeader, onLogin }) {
  const { t, language, setLanguage } = useTranslation();
  const [opened, setOpened] = useState(false);
  const { instance } = useMsal();
  const { accounts } = useMsal();

  const onToggleClick = useCallback(() => {
    setScrollable(opened);
    setOpened(!opened);
  }, [opened]);

  const onLinkClick = useCallback(() => {
    setScrollable(true);
    setOpened(false);
  }, []);

  useEffect(() => {
    return () => {
      setScrollable(true);
    };
  }, []);

  return (
    <>
      <NavbarBox onClick={onToggleClick} withMinWidth={false}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="10"
          height="10"
        >
          {opened ? (
            <CloseIcon width="8" height="8" />
          ) : (
            <HamburgerIcon width="10" height="10" />
          )}
        </Box>
      </NavbarBox>

      {opened && (
        <Box
          position="fixed"
          zIndex="999999"
          top={smallHeader ? "menuHeightSmall" : "menuHeight"}
          left="0"
          right="0"
          bottom="0"
          backgroundColor="dark.200"
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          {links.map((link, i) => (
            <RouteLink
              key={`header-nav-link-${i}-${link.text}`}
              to={link.link}
              onClick={onLinkClick}
            >
              <Text
                color="white"
                fontWeight="bold"
                fontSize="md"
                minHeight="3.5xl"
              >
                {t(link.text)}
              </Text>
            </RouteLink>
          ))}
          <AuthenticatedTemplate>
            {accounts ? (
              <RouteLink to="/funds">
                <Text
                  color="white"
                  fontWeight="bold"
                  fontSize="md"
                  minHeight="3.5xl"
                >
                  {t("link@funds")}
                </Text>
              </RouteLink>
            ) : null}
            <a
              href="#"
              onClick={() =>
                instance.logoutRedirect({ postLogoutRedirectUri: "/" })
              }
            >
              <Text
                color="white"
                fontWeight="bold"
                fontSize="md"
                minHeight="3.5xl"
              >
                {t("link@sign_out")}
              </Text>
            </a>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <a href="#" onClick={onLogin}>
              <Text
                color="white"
                fontWeight="bold"
                fontSize="md"
                minHeight="3.5xl"
              >
                {t("link@login")}
              </Text>
            </a>
          </UnauthenticatedTemplate>

          <Box display="flex">
            {Object.keys(locales).map((locale) => (
              <Text
                fontWeight="bold"
                cursor={language === locale ? "default" : "pointer"}
                color={language === locale ? "gray" : "white"}
                as="span"
                marginX="1rem"
                textAlign="center"
                fontSize="0.85rem"
                key={`mobile-locale-${locale}`}
                onClick={(e) => setLanguage(locale)}
              >
                {locales[locale]}
              </Text>
            ))}
          </Box>
        </Box>
      )}
    </>
  );
}

export default MobileMenu;
