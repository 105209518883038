import { useContext } from "react";
import { TranslationContext } from "../context/translation";

export const locales = {
    "en-US": "ENG",
    "ru-RU": "RUS",
    "hy-AM": "ARM",
};
  
export const useTranslation = () => {
  const context = useContext(TranslationContext);
  if (!context) {
    throw new Error(
      "useTranslation must be used within an TranslationProvider"
    );
  }

  return context;
};
