const english = {
  'link@about': 'About',
  'link@philosophy': 'Philosophy',
  'link@regulation': 'Regulation',
  'link@news': 'News',
  'link@industries': 'Industries',
  'link@contacts': 'Contact us',
  'link@login': 'Login',
  'link@funds': 'My Funds',
  'link@sign_out': 'Sign out',

  'footer@copyright': 'All Rights Reserved.',

  'component@action-button@read-more': 'Read more',
  'component@action-button@see-all': 'See all',

  'contact@name': 'Name',
  'contact@name-error': 'The name field is required.',
  'contact@email': 'Email',
  'contact@email-error': 'The email address field is invalid.',
  'contact@message': 'Message',
  'contact@message-error': 'The message field is required.',
  'contact@send': 'Send',
  'contact@toast@success': 'Message sent successfully.',
  'contact@toast@failure': 'Failed to send the message.',

  team: 'Team',

  'page@home@hero@title-bold': 'Global Ideas',
  'page@home@hero@title-light': 'Local Touch',
  'page@home@hero@text':
    'We think that the success of lorem ipsum dolor is the main key',
  'page@home@about@title-bold': 'About',
  'page@home@about@title-light': 'Berkeley',
  'page@home@about@text':
    'Berkeley Capital CJSC is a fund management company established in August 2018. Our main goal is to establish and manage a multi-profile non-public contractual investment fund. ',
  'page@home@philosophy@title': 'Our philosophy',
  'page@home@philosophy@text@line-1':
    "Our company emphasizes the human being, human life, his family his security, the environment in which he lives. This is why in every of our projects we take into account the security of a human's life and the impact we have on him.",
  'page@home@philosophy@text@line-2':
    'Our philosophy is based on the most important stages of life - health, safety and comfort. We believe that all the rest human needs are included in these stages.',
  'page@home@philosophy@text@line-3':
    'In the picture we  tried to illustrate this idea of ​​ours, where the main stages intertwine to become one whole and form an integral part of our lives.',

  'page@philosophy@hero@title': 'Our philosophy',
  'page@philosophy@hero@text':
    'We think that the success of lorem ipsum dolor is the main key',

  'page@home@philosophy@investment@safety-security@title': 'Safety/Security',
  'page@home@philosophy@investment@comfort@title': 'Comfort',
  'page@home@philosophy@investment@health@title': 'Health',
  'page@home@philosophy@investment@health@e@effective-healthcare':
    'Effective healthcare',
  'page@home@philosophy@investment@health@e@effective-healthcare@hospitals':
    'Hospitals',
  'page@home@philosophy@investment@health@e@effective-healthcare@spa-medical':
    'Spa and medical wellness center',
  'page@home@philosophy@investment@health@e@effective-healthcare@rehabilitation':
    'Rehabilitation centers',
  'page@home@philosophy@investment@health@e@effective-healthcare@dental':
    'Dental clinics',
  'page@home@philosophy@investment@health@e@effective-healthcare@elderly':
    'Elderly care centers',
  'page@home@philosophy@investment@health@e@quality':
    'Quality medicine and medical appliances',
  'page@home@philosophy@investment@health@e@quality@biotech':
    'Biotech innovations',
  'page@home@philosophy@investment@health@e@quality@health-beauty':
    'Health and beauty product',
  'page@home@philosophy@investment@health@e@healthy-food':
    'Healthy food and organic farming',
  'page@home@philosophy@investment@health@e@healthy-food@organic-products':
    'Organic products',
  'page@home@philosophy@investment@health@e@healthy-food@herbs':
    'Herbs and herbal products',
  'page@home@philosophy@investment@health@e@healthy-food@restaurants':
    'Restaurants',
  'page@home@philosophy@investment@health@e@healthy-food@organic-winner':
    'Organic winery and other alcoholic beverages',
  'page@home@philosophy@investment@health@e@clean-farming':
    'Innovative environmentally clean farming',
  'page@home@philosophy@investment@health@e@clean-farming@wood': 'Wood farming',
  'page@home@philosophy@investment@health@e@clean-farming@energy':
    'Energy Farming',
  'page@home@philosophy@investment@health@e@lifestyle':
    'Healthy lifestyle and living',
  'page@home@philosophy@investment@health@e@lifestyle@spa-beauty':
    'Spa and beauty centers',
  'page@home@philosophy@investment@health@e@lifestyle@sport-resorts':
    'Sport resorts, centers and training facilities',
  'page@home@philosophy@investment@health@e@lifestyle@sport-events':
    'Sport activity and event',
  'page@home@philosophy@investment@health@e@lifestyle@active-tourism':
    'Active tourism',
  'page@home@philosophy@investment@safety-security@e@physical':
    'Physical safety',
  'page@home@philosophy@investment@safety-security@e@physical@safe-infrastructure':
    'Safe infrastructure',
  'page@home@philosophy@investment@safety-security@e@physical@residential-construction':
    'Prudent residential construction',
  'page@home@philosophy@investment@safety-security@e@physical@sustainability':
    'Environmental safety and sustainability',
  'page@home@philosophy@investment@safety-security@e@personal':
    'Personal financial safety',
  'page@home@philosophy@investment@safety-security@e@personal@fund-investment':
    'Fund and investment management',
  'page@home@philosophy@investment@safety-security@e@personal@personal-wealth':
    'Personal wealth management',
  'page@home@philosophy@investment@safety-security@e@personal@financial-insurance':
    'Financial insurance',
  'page@home@philosophy@investment@safety-security@e@education': 'Education',
  'page@home@philosophy@investment@safety-security@e@education@youth-sporting':
    'Youth sporting and general education',
  'page@home@philosophy@investment@safety-security@e@education@professional-seminars':
    'Professional seminars, conferences and training courses',
  'page@home@philosophy@investment@safety-security@e@education@art-education':
    'Art education and talent recruitment',
  'page@home@philosophy@investment@comfort@e@leisure':
    'Leisure, rest and free time',
  'page@home@philosophy@investment@comfort@e@leisure@hotels-casino':
    'Hotels and casino',
  'page@home@philosophy@investment@comfort@e@leisure@resorts': 'Resorts',
  'page@home@philosophy@investment@comfort@e@leisure@sport':
    'Sport and entertainment centers',
  'page@home@philosophy@investment@comfort@e@leisure@parks':
    'Entertainment parks',
  'page@home@philosophy@investment@comfort@e@leisure@events': 'Events',
  'page@home@philosophy@investment@comfort@e@infrastructure': 'Infrastructure',
  'page@home@philosophy@investment@comfort@e@infrastructure@transportation':
    'Effective public transportation and mobility',
  'page@home@philosophy@investment@comfort@e@infrastructure@energy':
    'Energy sustainability',
  'page@home@philosophy@investment@comfort@e@infrastructure@clean-environment':
    'Clean environment (waste processing)',
  'page@home@philosophy@investment@comfort@e@infrastructure@parking-traffic':
    'Accessible city parking and traffic comfort',
  'page@home@philosophy@investment@comfort@e@social-comfort': 'Social comfort',
  'page@home@philosophy@investment@comfort@e@social-comfort@event-production':
    'Event, production and entertainment',
  'page@home@philosophy@investment@comfort@e@social-comfort@restaurants':
    'Restaurants, bars, clubs and music events',
  'page@home@philosophy@investment@comfort@e@living': 'Comfortable living',
  'page@home@philosophy@investment@comfort@e@living@innovative':
    'Innovative home products and services',
  'page@home@philosophy@investment@comfort@e@living@shopping-malls':
    'Accessible shopping malls and food courts',
  'page@home@philosophy@investment@comfort@e@affordable': 'Affordable habitat',
  'page@home@philosophy@investment@comfort@e@affordable@residential-housing':
    'Modern comfortable residential housing (urban and country)',
  'page@home@philosophy@investment@comfort@e@business':
    'Comfortable business and professional environment',
  'page@home@philosophy@investment@comfort@e@business@business-conferencing-centers':
    'Modern accessible business centers and conferencing centers',
  'page@home@philosophy@investment@comfort@e@business@summit-organization':
    'Conference and summit organization',
  'page@home@philosophy@investment@comfort@e@business@marketing-service':
    'Marketing service',

  'page@home@regulation@title': 'Regulation',
  'page@home@regulation@text':
    'Investment funds in Armenia are governed by the Republic of Armenia law “On Investment Funds” (hereinafter the Law), other laws and a range of regulations issued by the Central Bank of Armenia - the regulatory and supervisory body for investment fund field in Armenia.',
  'page@home@experience@title': 'Experience',
  'page@home@experience@text':
    'We think that the success of lorem ipsum dolor is the main key.',
  'page@home@experience@card1@title': 'Funds Description',
  'page@home@experience@card1@text': 'Complex population',
  'page@home@experience@card2@title': 'Investment Strategy',
  'page@home@experience@card2@text': 'Precision medicine',
  'page@home@experience@card3@title': 'Investors Process',
  'page@home@experience@card3@text': 'Person centric ecosystem',
  'page@home@fund@title-bold': 'Global Profix',
  'page@home@fund@title-light': 'AMD Fund',
  'page@home@fund@text':
    'Based on the goals and philosophy of our company, we implement programs aimed at improving urban infrastructure, as well as contribute to the creation of a comfortable and safe environment for people.',
  'page@home@news@title-bold': 'Our',
  'page@home@news@title-light': 'News',
  'page@home@contact@title-bold': 'Write',
  'page@home@contact@title-light': 'to us',
  'page@home@contact@text':
    'If you have questions or suggestions please feel free to contact us. We will reply by email within 1 business day.',

  'page@about-us@hero@title-bold': 'About',
  'page@about-us@hero@title-light': 'Berkeley',
  'page@about-us@hero@text':
    'We think that the success of lorem ipsum dolor is the main key',
  'page@about-us@who-we-are@title-bold': 'Who',
  'page@about-us@who-we-are@title-light': 'We Are',
  'page@about-us@who-we-are@line-1':
    'Berkeley Capital is a closed joint stock company established in August 2018.',
  'page@about-us@who-we-are@line-2':
    'Our interests are diverse: they are directed at financial as well as direct investments mainly in commercial and residential construction, infrastructure and public utilities, leisure and entertainment, manufacturing, power and alternative energy, bio-technology R&D and production.',
  'page@about-us@experience@title': 'Experience',
  'page@about-us@experience@line-1':
    'Fund programs can be both financial and investment.',
  'page@about-us@experience@line-2':
    'Berkeley Capital has brought together a solid team of skillful financial experts responsible for innovative, genuine solutions and approaches to business, strong problem-solving skills, and cutting-edge financial products, being inspired and empowered by our vision and stimulus for entrepreneurship.',
  'page@about-us@experience@line-3':
    'Our business-drive pursuits and exploits lucrative business opportunities and forms an incredible commercial force aimed at maximizing the value for our investors, providing them with a solid and steady financial well-being.',

  'page@news@hero@title-bold': 'News',
  'page@news@hero@title-light': '',
  'page@news@hero@text':
    'We think that the success of lorem ipsum dolor is the main key',
  'page@news@article@title-bold': 'Popular',
  'page@news@article@title-light': 'Articles',
  'page@news@article@show-more': 'Show more',
  'page@news@article@sort@title': 'Show by',
  'page@news@article@sort@title-az': 'Title (A-Z)',
  'page@news@article@sort@title-za': 'Title (Z-A)',
  'page@news@article@sort@date-09': 'Date (0-9)',
  'page@news@article@sort@date-90': 'Date (9-0)',

  'page@regulation@hero@title-bold': 'Regulation',
  'page@regulation@hero@title-light': '',
  'page@regulation@hero@text':
    'We think that the success of lorem ipsum dolor is the main key',
  'page@regulation@info@text-1':
    'Investment funds in Armenia are governed by the Republic of Armenia law “On Investment Funds” (hereinafter the Law), other laws and a range of regulations issued by the Central Bank of Armenia - the regulatory and supervisory body for investment fund field in Armenia.',
  'page@regulation@info@text-2':
    'The Law was adopted in December 2010 and taken effect in January 2011 and sets out regulatory regime for registration and operation of investment funds and fund managers in Armenia, as well as defines the type of investment funds and requirement to each type.',
  'page@regulation@info@text-3':
    'The Law regulates both public and private/non-public funds. In order to a fund to qualify for the non-public regime, the fund investors should not exceed 49 and the shares/units of fund cannot be distributed publicly.',
  'page@regulation@info@text-4':
    'The Law applies to non-public funds only to a very limited extent, particularly focusing on the registration process.',
  'page@regulation@info@text-5':
    'Here are official links about laws on Investment Funds',
  'page@regulation@info@text-6': 'Law on Investment Funds',
  'page@regulation@info@text-6-link':
    'https://www.cba.am/EN/lalaws/Investment_funds.pdf',
  'page@regulation@info@text-7':
    'Regulation 10/29 “Procedure on Online Registration of Non-Public Contractual Investment Funds”',
  'page@regulation@info@text-7-link':
    'https://www.cba.am/EN/laregulations/Regulation_10.29.pdf',
  'page@regulation@info@text-8':
    'Regulation 10/04 on the Procedure of Calculation of Net Asset Value of Investment Funds',
  'page@regulation@info@text-8-link':
    'https://www.cba.am/EN/laregulations/Regulation_10.04.pdf',
  'page@regulation@info@text-9':
    'Regulation 10/03 “Reports on Own Funds and Funds Managed by Investment Fund Manager Filed to the Central Bank of Armenia by Investment Fund Manager; Procedure, Terms and Conditions of Reporting; Providing the Central Bank of Armenia with Additional Information on Making Individual Decisions by Investment Fund Manager” [no direct link, the file format is Word and is automatically downloaded from the CBA’s webpage] – we can exclude from the list, if inconvenient.',
  'page@regulation@info@text-10':
    'For more information about the laws and regulations on investment funds you can visit',
  'page@regulation@info@text-10-link':
    'https://www.cba.am/en/SitePages/lawsinvestmentmain.aspx',
  'page@regulation@info@text-10-link-title':
    'official website of the Central Bank of Armenia',

  'page@industries@hero@title-bold': 'Industries',
  'page@industries@hero@title-light': '',
  'page@industries@hero@text':
    'We think that the success of lorem ipsum dolor is the main key',
  'page@industries@info@title': 'Projects',
  'page@industries@info@line-1':
    'Berkeley Capital keeps a vision of a wide scope. We target the vital and resolve the most complex. We come up with fiduciary projects for improvement of city infrastructure and implements creation of affordable and dignified habitat.',
  'page@industries@info@line-2':
    'We aim at dealing with not only the physical, but also the human sector, setting up perfect conditions for investments in human well-being. This includes quality leisure activities, people mobility, conducing to sport recreation and increase of human health-quality.',
  'page@industries@strategy@title-1': 'Investment',
  'page@industries@strategy@title-2': 'Strategy',
  'page@industries@strategy@text-top': 'Our industry of interest:',
  'page@industries@strategy@item-1': 'Construction',
  'page@industries@strategy@item-2': 'Infrastructure',
  'page@industries@strategy@item-3': 'Leisure, entertainment and tourism',
  'page@industries@strategy@item-4': 'Manufacturing',
  'page@industries@strategy@item-5': 'Energy',
  'page@industries@strategy@item-6': 'Biotech',
  'page@industries@strategy@text-bottom':
    'The company, as a source of financing for the investment programs considers the funds of the members of the closed club of investors.',

  'page@contact@hero@title-bold': 'Contact',
  'page@contact@hero@title-light': 'Us',
  'page@contact@hero@text':
    'If you have questions or suggestions please feel free to contact us.',
  'page@contact@title@email': 'Email',
  'page@contact@title@phone': 'Phone number',
  'page@contact@title@address': 'Address',
  'page@contact@title@address-value-1': 'Armenia, 0023, Yerevan',
  'page@contact@title@address-value-2': 'Bryusov St., 6 Building',
  'page@contact@title-bold': 'Send',
  'page@contact@title-light': 'a message',
  'page@contact@text':
    'We work with leading healthcare companies, venture capital firms and renowned accelerators.',

  'page@login@hero@title-bold': 'Login',
  'page@login@title-bold': 'Login',
  'page@login@title-light': 'to your page',

  'login@asset_management': 'Login to Asset Management',
  'login@fund_management': 'Login to Fund Management',

  'page@funds@hero@title-bold': 'Funds',
};

export default english;
