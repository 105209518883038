import React, { useMemo } from "react";
import { RouteLink } from "../../components/RouteLink";
import { Box, Flex, Img, Text } from "@chakra-ui/react";
import { useTranslation } from "../../core/hooks/useTranslation";
import ViewportAnimation from "../../components/ViewportAnimation";
import PageContainer from "../../components/PageContainer";
import SocialLink from "./SocialLink";
import imgFacebook from "../../images/icons/facebook.svg";
import imgLinkedin from "../../images/icons/linkedin.svg";
import imgTwitter from "../../images/icons/twitter.svg";
import imgLogo from "../../images/logo.png";
import links from "../../links";
import animation from "./_animation";

function Footer() {
  const { t } = useTranslation();

  const year = useMemo(() => new Date().getFullYear(), []);

  return (
    <Box
      backgroundColor="dark.200"
      minHeight="7.5xl"
      paddingY="2.8xl"
      color="white"
    >
      <PageContainer>
        <Box>
          <Flex
            flexDirection={{ base: "column", lg: "row" }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box maxHeight="3.5xl" minHeight="3.5xl">
              <ViewportAnimation {...animation.logo}>
                <Img height="3.5xl" draggable={false} src={imgLogo} />
              </ViewportAnimation>
            </Box>

            <Box
              fontWeight="light"
              fontSize="sm"
              paddingTop={{ base: "3.2xl", lg: "0" }}
            >
              <Flex
                justifyContent={{ base: "center", md: "space-between" }}
                flexDirection={{ base: "column", md: "row" }}
                alignItems="center"
                maxWidth={{ base: "100%", md: "11xl", lg: "12xl" }}
                minWidth={{ base: "100%", md: "11xl", lg: "12xl" }}
              >
                {links.map((link, i) => (
                  <Box
                    key={`footer-link-${i}-${link.text}`}
                    marginY={{ base: "xl", md: 0 }}
                    marginLeft={{
                      base: undefined,
                      md: 0 === i ? undefined : "2xl",
                    }}
                  >
                    <ViewportAnimation {...animation[`links${i + 1}`]}>
                      <RouteLink to={link.link}>{t(link.text)}</RouteLink>
                    </ViewportAnimation>
                  </Box>
                ))}
              </Flex>
            </Box>
          </Flex>
        </Box>

        <Box paddingTop="3.2xl">
          <Flex
            flexDirection={{ base: "column", lg: "row" }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Text fontSize="0.5xs" fontWeight="light">
                &copy; {year} Berkeley Inc. {t("footer@copyright")}
              </Text>
            </Box>

            <Box
              maxHeight="xl"
              minHeight="xl"
              marginTop={{ base: "3.2xl", lg: "0" }}
            >
              <Flex
                justifyContent="space-between"
                // maxWidth="5.5xl"
                // minWidth="5.5xl"
                maxWidth="4xl"
                minWidth="4xl"
              >
                <ViewportAnimation {...animation.socialLinks1}>
                  <SocialLink
                    link="https://www.facebook.com/berkeleyfund"
                    image={imgFacebook}
                  />
                </ViewportAnimation>
                <ViewportAnimation {...animation.socialLinks2}>
                  <SocialLink
                    link="https://www.linkedin.com/company/berkeley-capital-cjsc/"
                    image={imgLinkedin}
                  />
                </ViewportAnimation>
                {/* <ViewportAnimation {...animation.socialLinks3}>
                  <SocialLink link="https://twitter.com/" image={imgTwitter} />
                </ViewportAnimation> */}
              </Flex>
            </Box>
          </Flex>
        </Box>
      </PageContainer>
    </Box>
  );
}

export default Footer;
