import React from "react";
import { Box } from "@chakra-ui/react";

function PageContainer({ noSpace, lessWidth, children }) {
  return (
    <Box
      position="relative"
      maxWidth={lessWidth ? "pageContainerLess" : "pageContainer"}
      paddingX={noSpace ? 0 : "pageContainer"}
      margin="0 auto"
      height="100%"
    >
      {children}
    </Box>
  );
}

PageContainer.defaultProps = {
  noSpace: false,
  lessWidth: false,
};

export default PageContainer;
