const delayDelta = 0.32;

const logoAnimation = {
  initial: {
    transform: "scale(0)",
  },
  animate: {
    transform: "scale(1)",
  },
  transition: {
    duration: 0.53,
    delay: 0.3,
  },
};

const linkAnimationData = {
  style: {
    position: "relative",
  },
  initial: {
    opacity: 0,
    top: "2rem",
  },
  animate: {
    opacity: 1,
    top: 0,
  },
  transition: {
    duration: 0.48,
    delay: 0.53,
  },
};

const socialLinkAnimationData = {
  style: {
    position: "relative",
  },
  initial: {
    opacity: 0,
    left: "5rem",
  },
  animate: {
    opacity: 1,
    left: 0,
  },
  transition: {
    duration: 0.53,
    delay: 0.53,
  },
};

const links = Array.from({ length: 10 }).reduce((acc, val, index) => {
  const data = JSON.parse(JSON.stringify(linkAnimationData));
  data.transition.delay = index * delayDelta;

  acc[`links${index + 1}`] = data;
  return acc;
}, {});

const socialLinks = Array.from({ length: 3 }).reduce((acc, val, index) => {
  const data = JSON.parse(JSON.stringify(socialLinkAnimationData));
  data.transition.delay = index * delayDelta;

  acc[`socialLinks${index + 1}`] = data;
  return acc;
}, {});

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  logo: logoAnimation,
  ...links,
  ...socialLinks,
};
