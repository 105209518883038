import { I18n } from "aws-amplify";

import msgEnUs from "./messages/en-us";
import msgRuRu from "./messages/ru-ru";
import msgHyAm from "./messages/hy-am";

export const configure = () => {
  I18n.configure({
    supportedLngs: ["en-US", "ru-RU", "hy-AM"],
    fallbackLng: "en-US",
  });

  I18n.putVocabularies({
    "en-US": msgEnUs,
    "ru-RU": msgRuRu,
    "hy-AM": msgHyAm,
  });
};
