import React, { useEffect, useState, useCallback } from "react";
import { QueryClientProvider, QueryClient } from "react-query";
import { ChakraProvider, Box } from "@chakra-ui/react";
import { ParallaxProvider } from "react-scroll-parallax";
import TranslationProvider from "../../core/context/translation/provider";
import { AuthProvider } from "../../core/context/auth/provider";
import theme from "../../theme";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";

import "swiper/css";
import "swiper/css/free-mode";
import "./style.fonts.css";
import "./style.css";

// import "./register-sw";

const queryClient = new QueryClient();
const SCROLL_OFFSET = 50;
const ANIMATION_SPEED = 500;

function App({ children }) {
  const [scrolled, setScrolled] = useState(false);

  const onScroll = useCallback(() => {
    if (window.scrollY > SCROLL_OFFSET) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  }, []);

  useEffect(() => {
    if (window && document) {
      document.title = "Berkeley";

      window.addEventListener("scroll", onScroll);
      return () => {
        window.removeEventListener("scroll", onScroll);
      };
    }
  }, []);

  const height = scrolled ? "menuHeightSmall" : "menuHeight";

  return (
    <QueryClientProvider client={queryClient}>
      <ParallaxProvider>
        <TranslationProvider>
          <AuthProvider>
            <ChakraProvider theme={theme}>
              <Box>
                <Box
                  background="dark.200"
                  position="fixed"
                  zIndex={10000}
                  top={0}
                  left={0}
                  right={0}
                  height={height}
                  transition={`height ${ANIMATION_SPEED}ms`}
                >
                  <Header smallHeader={scrolled} animationSpeed={ANIMATION_SPEED} />
                </Box>

                <Box
                  minHeight="calc(100vh - 394px)"
                  transition={`margin-top ${ANIMATION_SPEED}ms`}
                  marginTop={height}
                >
                  <>{children}</>
                </Box>
                <Footer />
              </Box>
            </ChakraProvider>
          </AuthProvider>
        </TranslationProvider>
      </ParallaxProvider>
    </QueryClientProvider>
  );
}

export default App;
