import React from "react";
import { Link } from "gatsby";
import { Box, Img } from "@chakra-ui/react";

function SocialLink({ link, image }) {
  return (
    <Link to={link} target="_blank">
      <Box
        width="xl"
        height="xl"
        background="blue.600"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Img draggable={false} width="100%" height="100%" src={image} />
      </Box>
    </Link>
  );
}

export default SocialLink;
