import React, { useState, useEffect, useCallback } from "react";
import { I18n } from "aws-amplify";
import { TranslationContext, getFromStorage, putIntoStorage } from ".";

function TranslationProvider(props) {
  const [appLanguage, setAppLanguage] = useState(getFromStorage());
  const [mounted, setMounted] = useState(false);

  const updateLanguage = useCallback((newLanguage) => {
    I18n.setLanguage(newLanguage);
    setAppLanguage(newLanguage);

    if ("undefined" !== typeof document) {
      document.body.setAttribute("data-app-language", newLanguage);
    }
  }, []);

  const setLanguage = useCallback((newLanguage) => {
    if (newLanguage && ["en-US", "ru-RU", "hy-AM"].includes(newLanguage)) {
      putIntoStorage(newLanguage);
      updateLanguage(newLanguage);
    }
  }, []);

  const translate = useCallback((...args) => {
    return I18n.get(...args);
  }, []);

  useEffect(() => {
    const localeFromStorage = getFromStorage();
    updateLanguage(localeFromStorage);
    setMounted(true);
  }, []);

  const value = {
    language: appLanguage,
    t: translate,
    setLanguage: setLanguage,
  };

  return (
    <TranslationContext.Provider value={value}>
      {mounted && <>{props.children}</>}
    </TranslationContext.Provider>
  );
}

export default TranslationProvider;
