const links = [
  { link: "/about", text: "link@about" },
  { link: "/philosophy", text: "link@philosophy" },
  { link: "/regulation", text: "link@regulation" },
  { link: "/news", text: "link@news" },
  { link: "/industries", text: "link@industries" },
  { link: "/contact-us", text: "link@contacts" },
];

export default links;
