import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    body: "Mont, serif",
    heading: "Mont, serif",
  },

  fontWeight: {
    light: 300,
    regular: 400,
    semibold: 600,
    bold: 700,
    extrabold: 800,
  },

  fontSizes: {
    "0.5xs": "0.65rem",
    xs: "0.8rem",
    sm: "0.9rem",
    "1.5sm": "1.1rem",
    "2sm": "1.2rem",
    "3sm": "1.25rem",
    md: "1.1rem",
    xl: "1.4rem",
    "1.5xl": "1.92rem",
    "1.9xl": "1.95rem",
    "2xl": "2.3rem",
    "3xl": "2.5rem",
    "4xl": "2.12rem",
    "5xl": "4.1rem",
  },

  lineHeights: {
    sm: "1.125rem",
    "2xl": "1.75rem",
    "3xl": "1.875rem",
    "3.5xl": "1.2em",
    "4xl": "3.5625rem",
    "5xl": "5.75rem",

    "fs-2xl": "2.85rem",
    "fs-2xl-am": "3rem",
    "fs-3xl": "2.75rem",
    "fs-3xl-am": "3.5rem",
    "fs-5xl": "4.5rem",
    "fs-5xl-am": "5.5rem",
  },

  space: {
    md: "0.25rem",
    lg: "0.5rem",
    xl: "0.75rem",
    "2xl": "1.875rem",
    "2.2xl": "2.5rem",
    "2.3xl": "2.375rem",
    "2.5xl": "2.75rem",
    "2.8xl": "3.125rem",
    "3xl": "3.3125rem",
    "3.2xl": "4.5rem",
    "3.5xl": "5rem",
    "4xl": "6.25rem",
    "4.2xl": "6.0625rem",
    "5xl": "7.5rem",
    "6xl": "8.375rem",

    headerLogoML: "3.84rem",
    menuHeight: "6.25rem",
    menuHeightSmall: "4.5rem",
    pageContainer: "1.125rem",

    cardY: "4.375rem",
    cardX: "2.5rem",

    viewTeamImgTop: "6.8125rem",
    viewHomeSafetyTitleHalf: "9.375rem",
    viewHomeSafetyTitleHalfMobile: "4.6875rem",
    viewHomeSafetyCircleMainMobile: "3.0625rem",
    viewHomeSafetyCircleMain: "4.6021875rem",
    viewHomeSafetyCircleCoverMobile: "3.75rem",
    viewHomeSafetyCircleCover: "5.625rem",
    viewHomeRegulationCardColorT: "16.875rem",
    viewHomeRegulationCardP: "3.5625rem",
    viewHomeRegulationCardPSm: "6.5625rem",
    viewHomeExperienceCard: "3.75rem",
    viewHomeExperienceRead: "2.5rem",
    viewHomeExperienceCardGap: "1.75rem",
    viewHomeExperienceCardLine: "1.46875rem",
    viewHomeExperienceCardTitle: "1.25rem",

    viewTeamMemberTextTop: "2.5rem",
    viewTeamMemberTextLeft: "1.25rem",

    viewHomeContactTop: "9.9375rem",
    viewHomeContactInputRow: "2.22rem",
    viewAboutExperienceGradientTop: "11rem",
    viewAboutExperienceCardPSm: "6.5625rem",
    viewAboutExperienceCardP: "3.5625rem",

    viewHomeLoginInputRow: "2.22rem",

    viewFundsFundsT: "7.5rem",
    viewFundsFundsB: "3.75rem",
    viewFundsFundsSpaceT: "3.5625",
    viewFundsFundsGradientTop: "11rem",
    viewFundsFundsCardPSm: "6.5625rem",
    viewFundsFundsCardP: "3.5625rem",

    viewFundsStrategyP: "7.9375rem",
    viewFundsStrategyImgOut: "4.3125rem",

    viewFundsProfileT: "5.625rem",
    viewFundsProfileB: "12.5rem",
    viewFundsProfileAreaGradP: "4.375rem",
    viewFundsProfileAreaGradMT: "5rem",
    viewFundsProfileAreaT: "3.875rem",
    viewFundsProfileTableT: "2.75rem",
    viewFundsProfileRowB: "1.375rem",

    viewNewsArticlesT: "6.25rem",
    viewNewsArticlesContentT: "4.375rem",
    viewNewsArticlesMoreT: "4.875rem",
    viewNewsArticlesMoreB: "7.3125rem",

    viewNewsInfoB: "12.5rem",
    viewNewsInfoTextY: "1.375rem",
    viewNewsInfoTextL: "6.25rem",
  },

  sizes: {
    xl: "2.625rem",
    "2xl": "2.75rem",
    "3xl": "4.25rem",
    "3.2xl": "4.4375",
    "3.5xl": "5rem",
    "4xl": "7.25rem",
    "4.5xl": "7.4375rem",
    "5xl": "11.25rem",
    "5.5xl": "12.5rem",
    "6xl": "6.25rem",
    "6.9xl": "12.5rem",
    "7xl": "16.25rem",
    "7.5xl": "17.5rem",
    "8xl": "25rem",
    "9xl": "30rem",
    "10xl-s": "21.875rem",
    "10xl": "32.5rem",
    "11xl": "38rem",
    "12xl": "42rem",

    headerLogoH: "3.5625rem",
    headerLogoHSmall: "3rem",
    menuHeight: "6.25rem",
    menuHeightSmall: "4.5rem",
    heroImageW: "9.375rem",

    pageContainer: "75.5rem",
    pageContainerLess: "63rem",

    viewHomeAbout: "41.25rem",
    viewHomeAboutCard: "31.25rem",
    viewHomePhilosophy: "36.9375rem",
    viewHomeSafetyMobile: "50rem",
    viewHomeSafetyMore: "48rem",
    viewHomeSafetyLess: "34rem",
    viewHomeSafetyTitle: "18.75rem",
    viewHomeSafetyTitleMobile: "10rem",
    viewHomeSafetyCircleMainMobile: "6.125rem",
    viewHomeSafetyCircleMain: "9.204375rem",
    viewHomeSafetyCircleCoverMobile: "7.5rem",
    viewHomeSafetyCircleCover: "11.25rem",
    viewHomeSafetyCircleImageMobile: "2.5rem",
    viewHomeSafetyCircleImage: "3.75rem",
    viewHomeRegulation: "43.75rem",
    viewHomeRegulationCardW: "31.25rem",
    viewHomeRegulationCardWSmall: "20.25rem",
    viewHomeRegulationCardH: "36.25rem",
    viewHomeRegulationCardHSmall: "23.49rem",
    viewHomeRegulationCardColorW: "50.875rem",
    viewHomeRegulationCardColorWSmall: "44.25rem",
    viewHomeRegulationCardColorWXXSmall: "18.5rem",
    viewHomeRegulationCardColorH: "24rem",
    viewHomeExperience: "40.3125rem",
    viewHomeExperienceCardH: "18.125rem",
    viewHomeExperienceCardImage: "9.375rem",
    viewHomeExperienceCardTitle: "1.3125rem",
    viewHomeExperienceCardText: "1.25rem",
    viewHomeFundH: "37.5rem",
    viewTeam: "43.75rem",
    viewTeamImgW: "13.125rem",
    viewTeamImgH: "31.25rem",
    viewTeamImageW: "12.5rem",
    viewTeamImageH: "17.5rem",
    viewTeamMemberW: "20.75rem",
    viewHomeContact: "39.8125rem",
    viewHomeContactCol1: "26.25rem",
    viewHomeContactCol2: "35.75rem",
    viewHomeContactInputW: "17rem",

    viewAboutWhoWeAreW: "60.75rem",
    viewAboutExperience: "51.3125rem",
    viewAboutExperienceGradientH: "24.375rem",
    // viewAboutExperienceCardH: "36.25rem",
    viewAboutExperienceCardHSmall: "28.125rem",
    viewAboutExperienceCardWLeft: "29.5rem",
    viewAboutExperienceCardWRight: "37rem",
    viewAboutExperienceCardWSmallLeft: "20.25rem",
    viewAboutExperienceCardWSmallRight: "20.25rem",
    viewAboutExperienceCardH: "42.5rem",
    viewAboutExpressionB: "2rem",

    viewContactH: "35rem",
    viewContactInfoW: "35.75rem",
    viewContactMapW: "23.25rem",
    viewContactMapH: "25rem",

    viewFundsFundsCardH: "36.25rem",
    viewFundsFundsCardHSmall: "28.125rem",
    viewFundsFundsCardW: "29.5rem",
    viewFundsFundsCardWSmall: "20.25rem",
    viewFundsFundsGradientH: "24rem",

    viewFundsStrategyH: "43.75rem",
    viewFundsStrategyCardW: "31.25rem",
    viewFundsStrategyImgH: "52.375rem",
    viewFundsStrategyImgW: "22rem",

    viewFundsProfileAreaGradW: "96.5277777778%",
    viewFundsProfileCardMaxW: "74.5rem",
    viewFundsProfileCardMinH: "16.25rem",
    viewFundsProfileCardCellMinW: "34.125rem",

    viewNewsInfoTextW: "26.75rem",
  },

  radii: {
    tooltip: "0.75rem",
    card: "1.25rem",
    teamImage: "0.75rem",

    bigCard: "3.75rem",
  },

  colors: {
    light: {
      200: "#ffffff",
      300: "#F1F1F9",
      400: "#f7f7f7",
    },
    dark: {
      100: "#000000",
      200: "#0f191d",
      300: "#1c1c1c",
    },
    gray: {
      100: "#323B40",
      200: "#dddddd",
    },
    blue: {
      100: "#4969B2",
      200: "#0088cd",
      400: "#155ca5",
      600: "#29378b",
      800: "#263f8d",
      900: "#25357c",
      1000: "#293B8A",
      1100: "#017FC1",
    },
    green: {
      100: " #28CA8B",
    },
    purple: {
      100: " #AA97FF",
    },
  },

  styles: {
    global: {
      a: {
        textDecoration: "none !important",
      },
    },
  },
});

export default theme;
