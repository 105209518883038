import { createContext } from "react";
import { configure as configureI18n } from "../../../translations";

export const defaultLang = "en-US";

const storageLangKey = "app_lang_key";
export const putIntoStorage = (value) => {
  if ("undefined" !== typeof window && window.localStorage) {
    window.localStorage.setItem(storageLangKey, value);
  }
};

export const getFromStorage = () => {
  if ("undefined" !== typeof window && window.localStorage) {
    return window.localStorage.getItem(storageLangKey) || defaultLang;
  }

  return defaultLang;
};

let language = getFromStorage();
configureI18n();

if ("undefined" !== typeof window && window.localStorage) {
  language = window.localStorage.getItem(storageLangKey) || defaultLang;
  window.localStorage.setItem(storageLangKey, language);
}

export const TranslationContext = createContext({
  language: defaultLang,
  setLanguage: (newLanguage) => {},
});
