import React, { useMemo } from "react";
import { motion } from "framer-motion";

function SectionAnimation({ children, ...animationProps }) {
  const isMobile = useMemo(() => {
    if ("undefined" !== typeof window) {
      return window.innerWidth < 576;
    }

    return false;
  }, []);

  if (isMobile) {
    return children;
  }

  return <motion.div {...animationProps}>{children}</motion.div>;
}

export default SectionAnimation;
