module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Berkeley","short_name":"Berkeley","start_url":"/","background_color":"#ffffff","theme_color":"#29378b","display":"standalone","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d2e4a6ecd98597250abb8da15a1916c1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/Users/harut/Desktop/own/berkeley/src/components/App/index.js"},
    },{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/fonts/*"],"runtimeCaching":[{"urlPattern":{},"handler":"cacheFirst","options":{"cacheableResponse":{"statuses":[0,200]},"cacheName":"static-webfonts","expiration":{"maxAgeSeconds":3600,"maxEntries":30}}}]},
    }]
