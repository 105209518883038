export default {
  "link@about": "О нас",
  "link@philosophy": "Наша философия",
  "link@regulation": "Регулирование",
  "link@news": "Новости",
  "link@industries": "Отрасли",
  "link@contacts": "Контакты",
  "link@login": "Войти",

  "footer@copyright": "Все права защищены.",

  "component@action-button@read-more": "Подробнее",
  "component@action-button@see-all": "Увидеть все",

  "contact@name": "Имя",
  "contact@name-error": "Необходимо ввести имя в поле.",
  "contact@email": "Эл. адрес",
  "contact@email-error": "Поле адреса электронной почты недействительно.",
  "contact@message": "Сообщение",
  "contact@message-error": "Поле сообщения является обязательным.",
  "contact@send": "Отправить",
  "contact@toast@success": "Message sent successfully.",
  "contact@toast@failure": "Не удалось отправить сообщение.",

  team: "Команда",

  "page@home@hero@title-bold": "Глобальные Идеи",
  "page@home@hero@title-light": "Конкретние Решения",
  "page@home@hero@text":
    "We think that the success of lorem ipsum dolor is the main key",
  "page@home@about@title-bold": "О",
  "page@home@about@title-light": "Berkeley",
  "page@home@about@text":
    "ЗАО «Беркли Капитал» - компания по управлению фондами, созданная в августе 2018 года. Наша основная цель - создание и управление многопрофильными, непубличными, договорными инвестиционными фондами.",
  "page@home@philosophy@title": "Наша философия",
  "page@home@philosophy@text@line-1":
    // "Человек, его жизнь, семья, безопасность и среда, в которой он живет, - очень значимo для нашей компании. В каждом нашем проекте мы учитываем безопасность жизни человека и то влияние, которое мы оказываем на него.",
    "Человек, его жизнь, семья, безопасность и среда, в которой он живет – основные ценности для нашей компании. В каждом нашем проекте мы учитываем безопасность жизни человека и то влияние, которое мы оказываем на него.",
  "page@home@philosophy@text@line-2":
    // "Наша философия основана на важнейших этапах жизни - здоровье, безопасность и комфорт людей. Мы считаем, что все потребности человека включены в данные стадии.",
    "Наша философия основана на важнейших сферах жизни - здоровье, безопасность и комфорт людей. Мы считаем, что все потребности человека включены в данные сферы.",
  "page@home@philosophy@text@line-3":
    // "На картинке мы проиллюстрировали нашу идею, где основные этапы переплетаются, чтобы стать единым целым и стать неотъемлемой частью нашей жизни.",
    "На картинке мы проиллюстрировали нашу идею, где основные сферы переплетаются, чтобы стать единым целым и стать неотъемлемой частью нашей жизни.",

  "page@philosophy@hero@title": "Наша философия",
  "page@philosophy@hero@text":
    "We think that the success of lorem ipsum dolor is the main key",

  "page@home@philosophy@investment@safety-security@title": "Безопасность",
  "page@home@philosophy@investment@comfort@title": "Комфорт",
  "page@home@philosophy@investment@health@title": "Здоровье",
  "page@home@philosophy@investment@health@e@effective-healthcare":
    "Здравоохранение",
  "page@home@philosophy@investment@health@e@effective-healthcare@hospitals":
    "Больницы",
  "page@home@philosophy@investment@health@e@effective-healthcare@spa-medical":
    "Спа и медицинские оздоровительные центры",
  "page@home@philosophy@investment@health@e@effective-healthcare@rehabilitation":
    "Реабилитационные центры",
  "page@home@philosophy@investment@health@e@effective-healthcare@dental":
    "Стоматологические клиники",
  "page@home@philosophy@investment@health@e@effective-healthcare@elderly":
    "Центры ухода за престарелыми",
  "page@home@philosophy@investment@health@e@quality":
    "Качественная медицина и медицинская техника",
  "page@home@philosophy@investment@health@e@quality@biotech":
    "Биотехнологические инновации",
  "page@home@philosophy@investment@health@e@quality@health-beauty":
    "Товары для здоровья и красоты",
  "page@home@philosophy@investment@health@e@healthy-food":
    "Здоровое питание и органические продукты",
  "page@home@philosophy@investment@health@e@healthy-food@organic-products":
    "Органические продукты",
  "page@home@philosophy@investment@health@e@healthy-food@herbs":
    "Травы и растительные продукты",
  "page@home@philosophy@investment@health@e@healthy-food@restaurants":
    "Рестораны",
  "page@home@philosophy@investment@health@e@healthy-food@organic-winner":
    "Органические винодельни и другие алкогольные напитки",
  "page@home@philosophy@investment@health@e@clean-farming":
    "Инновационное экологически чистое земледелие",
  "page@home@philosophy@investment@health@e@clean-farming@wood":
    "Лесное хозяйство",
  "page@home@philosophy@investment@health@e@clean-farming@energy":
    "Производство электроэнергии",
  "page@home@philosophy@investment@health@e@lifestyle": "Здоровый образ жизни",
  "page@home@philosophy@investment@health@e@lifestyle@spa-beauty":
    "Спа и салоны красоты",
  "page@home@philosophy@investment@health@e@lifestyle@sport-resorts":
    "Спортивные курорты, тренажерные залы и тренировочные комплексы",
  "page@home@philosophy@investment@health@e@lifestyle@sport-events":
    "Спортивная деятельность и мероприятия",
  "page@home@philosophy@investment@health@e@lifestyle@active-tourism":
    "Активный туризм",
  "page@home@philosophy@investment@safety-security@e@physical":
    "Физическая безопасность",
  "page@home@philosophy@investment@safety-security@e@physical@safe-infrastructure":
    "Безопасная инфраструктура",
  "page@home@philosophy@investment@safety-security@e@physical@residential-construction":
    "Качественные жилищные условия",
  "page@home@philosophy@investment@safety-security@e@physical@sustainability":
    "Экологическая безо пасность и устойчивость",
  "page@home@philosophy@investment@safety-security@e@personal":
    "Личная финансовая безопасность",
  "page@home@philosophy@investment@safety-security@e@personal@fund-investment":
    "Управление фондами и инвестициями",
  "page@home@philosophy@investment@safety-security@e@personal@personal-wealth":
    "Управление личным капиталом",
  "page@home@philosophy@investment@safety-security@e@personal@financial-insurance":
    "Финансовое страхование",
  "page@home@philosophy@investment@safety-security@e@education": "Образование",
  "page@home@philosophy@investment@safety-security@e@education@youth-sporting":
    "Молодежное спортивно-общеобразовательное",
  "page@home@philosophy@investment@safety-security@e@education@professional-seminars":
    "Профессиональные семинары, конференции и обучающие курсы",
  "page@home@philosophy@investment@safety-security@e@education@art-education":
    "Художественное образование и подбор талантов",
  "page@home@philosophy@investment@comfort@e@leisure":
    "Досуг, отдых и свободное время",
  "page@home@philosophy@investment@comfort@e@leisure@hotels-casino":
    "Гостиницы и казино",
  "page@home@philosophy@investment@comfort@e@leisure@resorts": "Курорты",
  "page@home@philosophy@investment@comfort@e@leisure@sport":
    "Спортивно-развлекательные центры",
  "page@home@philosophy@investment@comfort@e@leisure@parks":
    "Парки развлечений",
  "page@home@philosophy@investment@comfort@e@leisure@events": "Мероприятия",
  "page@home@philosophy@investment@comfort@e@infrastructure": "Инфраструктура",
  "page@home@philosophy@investment@comfort@e@infrastructure@transportation":
    "Oбщественный транспорт и мобильность",
  "page@home@philosophy@investment@comfort@e@infrastructure@energy":
    "Энергетическая устойчивость",
  "page@home@philosophy@investment@comfort@e@infrastructure@clean-environment":
    "Чистая окружающая среда (переработка отходов)",
  "page@home@philosophy@investment@comfort@e@infrastructure@parking-traffic":
    "Доступная городская парковка и комфортное движение",
  "page@home@philosophy@investment@comfort@e@social-comfort":
    "Социальный комфорт",
  "page@home@philosophy@investment@comfort@e@social-comfort@event-production":
    "Мероприятия, производство и развлечения",
  "page@home@philosophy@investment@comfort@e@social-comfort@restaurants":
    "Рестораны, бары, клубы и музыкальные мероприятия",
  "page@home@philosophy@investment@comfort@e@living": "Хорошие условия жизни",
  "page@home@philosophy@investment@comfort@e@living@innovative":
    "Инновационные товары и услуги для дома",
  "page@home@philosophy@investment@comfort@e@living@shopping-malls":
    "Доступные торговые центры и фуд-корты",
  "page@home@philosophy@investment@comfort@e@affordable":
    "Доступная среда обитания",
  "page@home@philosophy@investment@comfort@e@affordable@residential-housing":
    "Деловая и профессиональная среда",
  "page@home@philosophy@investment@comfort@e@business":
    "Современные доступные бизнес-центры и конференц-центры",
  "page@home@philosophy@investment@comfort@e@business@business-conferencing-centers":
    "Организация конференций и саммитов/семинаров",
  "page@home@philosophy@investment@comfort@e@business@summit-organization":
    "Conference and summit organization",
  "page@home@philosophy@investment@comfort@e@business@marketing-service":
    "Маркетинговые услуги",

  "page@home@regulation@title": "Регулирование",
  "page@home@regulation@text":
    "Инвестиционные фонды в Армении регулируются Законом РА «Об инвестиционных фондах» (далее - Закон), другими пунктами закона, Центральным банком Республики Армения, который является регулятором инвестиционных фондов в Армении, а также ряд нормативных актов, изданных надзорным органом.",
  "page@home@experience@title": "Опыт",
  "page@home@experience@text":
    "Программы фонда могут быть как финансовыми, так и инвестиционными.",
  "page@home@experience@card1@title": "Описание фондов",
  "page@home@experience@card1@text": "Complex population",
  "page@home@experience@card2@title": "Инвестиционная стратегия",
  "page@home@experience@card2@text": "Precision medicine",
  "page@home@experience@card3@title": "Инвесторский процесс",
  "page@home@experience@card3@text": "Person centric ecosystem",
  "page@home@fund@title-bold": "Глобальный профикс",
  "page@home@fund@title-light": "AMD Fund",
  "page@home@fund@text":
    "Исходя из целей и философии нашей компании, мы реализуем программы, направленные на улучшение городской инфраструктуры, так же способствуем созданию комфортную и благополучную среду для людей.",
  "page@home@news@title-bold": "Наши",
  "page@home@news@title-light": "новости",
  "page@home@contact@title-bold": "Напишите",
  "page@home@contact@title-light": "нам",
  "page@home@contact@text":
    "Если у вас есть вопросы или предложения, пожалуйста, свяжитесь с нами. Мы ответим по электронной почте в течение 1 рабочего дня.",

  "page@about-us@hero@title-bold": "О",
  "page@about-us@hero@title-light": "Berkeley",
  "page@about-us@hero@text":
    "We think that the success of lorem ipsum dolor is the main key",
  "page@about-us@who-we-are@title-bold": "Кто",
  "page@about-us@who-we-are@title-light": "мы",
  "page@about-us@who-we-are@line-1":
    // "«Беркли Капитал» - компания, созданная в августе 2018 года в формате закрытого акционерного общества.",
    "«Беркли Кэпитл» - компания, созданная в августе 2018 года в формате закрытого акционерного общества.",
  "page@about-us@who-we-are@line-2":
    // "Наши области работы разнообразны: коммерческая, бытовая, инфраструктурная, коммунальная, досуг и развлечения, производство, электроэнергия, альтернативная энергия, биотехнологии, исследования и производство.",
    "Сфера нашей деятельности разнообразна: коммерческая и жилая недвижимость, инфраструктура, коммунальные услуги, досуг и развлечения, производство, электроэнергия, альтернативная энергия, биотехнологии, исследования и производство.",
  "page@about-us@experience@title": "Опыт",
  "page@about-us@experience@line-1":
    "Программы фонда могут быть как финансовыми, так и инвестиционными.",
  "page@about-us@experience@line-2":
    "Наша команда финансовых консультантов способна решать проблемы, предлагая бизнесу не только новейшие финансовые продукты, но и реальные решения и подходы, исходя из цели продвижения партнеров по бизнесу.",
  "page@about-us@experience@line-3":
    "Наша идея заключается в том, чтобы создать и максимизировать ценность для наших инвесторов путем выявления и развития прибыльных бизнес-возможностей и обеспечения их устойчивого финансового благополучия.",

  "page@news@hero@title-bold": "Новости",
  "page@news@hero@title-light": "",
  "page@news@hero@text":
    "We think that the success of lorem ipsum dolor is the main key",
  "page@news@article@title-bold": "Популярные",
  "page@news@article@title-light": "Статьи ",
  "page@news@article@show-more": "Показать больше",
  "page@news@article@sort@title": "Сортировать по",
  "page@news@article@sort@title-az": "Заголовок (А-Я)",
  "page@news@article@sort@title-za": "Заголовок (Я-А)",
  "page@news@article@sort@date-09": "Дата (0-9)",
  "page@news@article@sort@date-90": "Дата (9-0)",

  "page@regulation@hero@title-bold": "Регулирование",
  "page@regulation@hero@title-light": "",
  "page@regulation@hero@text":
    "We think that the success of lorem ipsum dolor is the main key",
  "page@regulation@info@text-1":
    // "Инвестиционные фонды в Армении регулируются Законом РА «Об инвестиционных фондах» (далее - Закон), другими пунктами закона, Центральным банком Республики Армения, который является регулятором инвестиционных фондов в Армении, а также ряд нормативных актов, изданных надзорным органом.",
    "Инвестиционные фонды в Армении регулируются законом РА «Об инвестиционных фондах» (далее - Закон), другими законами, а также рядом нормативных актов, изданных Центральным банком Республики Армения- надзорным органом, регулирующим инвестиционные фонды в Армении.",
  "page@regulation@info@text-2":
    // "Закон был принят в декабре 2010 года и вступил в силу в январе 2011 года.Закон определяет «режим регулирования» для регистрации инвестиционных фондов и управляющих фондами в Армении, а также тип инвестиционных фондов и требования для каждого типа.",
    "Закон был принят в декабре 2010 года и вступил в силу в январе 2011 года. Закон определяет режим регулирования инвестиционных фондов в Армении, а также тип инвестиционных фондов и требования для каждого типа.",
  "page@regulation@info@text-3":
    // "Закон регулирует как государственные, так и частные / негосударственные фонды. В случае негосударственных закрытых фондов, согласно закону, число инвесторов не должно превышать 49. Доходы фонда не могут быть распределены среди населения.",
    "Закон регулирует как публичные, так и частные / непубличные фонды. Так, в случае непубличных фондов, согласно Закону, число инвесторов не должно превышать 49, а акции/паи фонда не могут быть размещены путем оферты.",
  "page@regulation@info@text-4": " ",
  "page@regulation@info@text-5":
    // "Ниже можете найти ссылки на государственный закон по управлению фондами:",
    "Ниже можете найти ссылки на ряд важных законов и нормативных актов:",
  "page@regulation@info@text-6":
    // "Закон об инвестиционных фондах",
    "Закон об инвестиционных фондах",
  "page@regulation@info@text-6-link":
    "https://www.cba.am/AM/lalaws/Nerdrumayin_rus.pdf",
  "page@regulation@info@text-7":
    // "Регламент: «10/29 ոչ հրապարակային պայմանագրային ներդրումային ֆոնդի կանոնների առցանց գրանցման ընթացակարգ»",
    "Положение 10/29 “Процедура онлайновой регистрации правил паевого договорного инвестиционного фонда”",
  "page@regulation@info@text-7-link":
    "https://www.cba.am/RU/Laregulations/Regulation_10.29.pdf",
  "page@regulation@info@text-8":
    // "Регламент 10/04 «Ներդրումային ֆոնդերի զուտ ակտիվների արժեքի հաշվարկման կարգը»",
    "Положение 10/04 “Порядок расчета стоимости чистых активов инвестиционных фондов”​",
  "page@regulation@info@text-8-link":
    "https://www.cba.am/RU/Laregulations/Regulation_10.04.pdf",
  "page@regulation@info@text-9":
    "Регламент 10/03 «Отчеты управляющего инвестиционным фондом в ЦБ о фондах, которыми он управляет, их формах, содержании, порядке представления, сроках и периодичность, порядок предоставления дополнительной информации в ЦБ по отдельным решениям, принимаемым управляющим“.",
  "page@regulation@info@text-10":
    // "Для получения дополнительной информации о законах и положениях об инвестиционных фондах посетите сайт",
    "Дополнительную и более детальную информации о законах и положениях об инвестиционных фондах можете найти на",
  "page@regulation@info@text-10-link":
    "https://www.cba.am/ru/SitePages/lawsinvestmentmain.aspx",
  "page@regulation@info@text-10-link-title": "официальном сайте Центрального банка Республики Армения",

  "page@industries@hero@title-bold": "Отрасли",
  "page@industries@hero@title-light": "",
  "page@industries@hero@text":
    "We think that the success of lorem ipsum dolor is the main key",

  "page@industries@info@title": "Проекты",
  "page@industries@info@line-1":
    // "Исходя из целей и философии нашей компании, мы реализуем программы, направленные на улучшение городской инфраструктуры, так же способствуем созданию комфортную и благополучную среду для людей.",
    "Исходя из целей и философии нашей компании, мы реализуем программы, направленные на улучшение городской инфраструктуры, так же способствуем созданию комфортной и благополучной среды для людей.",
  "page@industries@info@line-2":
    // "В случае человеческих инвестиций наша цель - работать как с физическими, так и с человеческими ресурсами.",
    "Мы стремимся работать не только с физическим сектором, а также стараемся создать идеальные условия для инвестиций в благосостояние людей. Это включает в себя качественный досуг, мобильность людей, способствование спортивному отдыху и повышение качества здоровья человека.",
  "page@industries@strategy@title-1": "Инвестиционная",
  "page@industries@strategy@title-2": "стратегия",
  "page@industries@strategy@text-top": "Наши проекты включают следующие направления:",
  "page@industries@strategy@item-1": "Строительство",
  "page@industries@strategy@item-2": "Инфраструктура",
  "page@industries@strategy@item-3": "Развлечения и туризм",
  "page@industries@strategy@item-4": "Производство",
  "page@industries@strategy@item-5": "Энергия",
  "page@industries@strategy@item-6": "Биотехнологии",
  "page@industries@strategy@text-bottom":
    "Компания, как источник финансирования своих инвестиционных проектов, рассматривает средства участников «Закрытого клуба инвесторов».",

  "page@contact@hero@title-bold": "Связаться",
  "page@contact@hero@title-light": "с нами",
  "page@contact@hero@text":
    "Если у вас есть вопросы или предложения, пожалуйста, свяжитесь с нами.",
  "page@contact@title@email": "Эл. адрес",
  "page@contact@title@phone": "Телефонный номер",
  "page@contact@title@address": "Адрес",
  "page@contact@title@address-value-1": "Армениа, 0023, Ереван",
  "page@contact@title@address-value-2": "ул. Брюсова, дом 6",
  "page@contact@title-bold": "Отправить",
  "page@contact@title-light": "сообщение",
  "page@contact@text":
    "Мы сотрудничаем с ведущими компаниями в сфере здравоохранения, венчурного инвестирования и известными акселераторами.",
};
