import React from "react";
import { RouteLink } from "../../components/RouteLink";
import { Box, Flex, Img } from "@chakra-ui/react";
import Navbar from "./Navbar";
import MobileMenu from "./MobileMenu";
import imgLogo from "../../images/logo.png";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig, loginRequest } from "../../config/authConfig";

export const msalInstance = new PublicClientApplication(msalConfig);

function Header({ smallHeader, animationSpeed }) {

  const handleLogin = (e) => {
    e.preventDefault();
    msalInstance.loginRedirect(loginRequest);
  };

  return (
    <MsalProvider instance={msalInstance}>
      <Flex
        height="100%"
        width="100%"
        justifyContent="space-between"
        backgroundColor="dark.200"
        borderBottom="1px solid"
        borderColor="gray.100"
        padding={{ base: 5, lg: 0 }}
        position="relative"
        zIndex="999"
      >
        <Box
          display="flex"
          alignItems="center"
          paddingLeft={{ base: 0, lg: "headerLogoML" }}
        >
          <RouteLink to="/">
            <Img
              draggable={false}
              src={imgLogo}
              transition={`height ${animationSpeed}ms`}
              height={smallHeader ? "headerLogoHSmall" : "headerLogoH"}
            />
          </RouteLink>
        </Box>

        <Box height="100%">
          <Box height="100%" display={{ base: "none", lg: "block" }}>
            <Navbar onLogin={handleLogin} />
          </Box>
          <Box height="100%" display={{ base: "block", lg: "none" }}>
            <MobileMenu smallHeader={smallHeader} onLogin={handleLogin} />
          </Box>
        </Box>
      </Flex>
    </MsalProvider>
  );
}

export default Header;
