import React from "react";
import { AuthContext } from ".";

export function AuthProvider(props) {
  const value = {
    isLogged: false,
  };

  return (
    <AuthContext.Provider value={value}>
      <>{props.children}</>
    </AuthContext.Provider>
  );
}
