import React from "react";
import { Box } from "@chakra-ui/react";
import { TransitionState } from "gatsby-plugin-transition-link";
import SectionAnimation from "../SectionAnimation";

function AnimatedContent({ background, children }) {
  return (
    <TransitionState>
      {({ transitionState, entry, exit }) => (
        <SectionAnimation
          className="main-menu"
          initial={entry.state}
          animate={
            transitionState === "exiting"
              ? exit.state
              : { opacity: 1, x: 0, y: 0 }
          }
          transition={
            transitionState === "exiting"
              ? { duration: exit.length }
              : { duration: 0.4 }
          }
        >
          <Box background={background}>{children}</Box>
        </SectionAnimation>
      )}
    </TransitionState>
  );
}

AnimatedContent.defaultProps = {
  background: "white",
};

export default AnimatedContent;
