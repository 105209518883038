import React, { useCallback } from "react";
import { Box, Flex } from "@chakra-ui/react";

function NavbarBox({
  children,
  section,
  filled,
  onClick,
  onSectionNavigate,
  withMinWidth,
}) {
  const onNavigateClick = useCallback(
    (e) => {
      if (section) {
        e.preventDefault();
        onSectionNavigate(section);
      }
    },
    [section, onSectionNavigate]
  );

  return (
    <Flex
      height="100%"
      alignItems="center"
      justifyContent="center"
      paddingX={{ base: "0", lg: "5" }}
      color="white"
      backgroundColor={filled ? "blue.600" : "transparent"}
      fontWeight="bold"
      fontSize="sm"
      minWidth={!withMinWidth ? 0 : { base: "3xl", lg: "0" }}
      width={filled ? "5xl" : ""}
      onClick={onClick}
    >
      <Box cursor="pointer" onClick={onNavigateClick}>
        {children}
      </Box>
    </Flex>
  );
}

NavbarBox.defaultProps = {
  withMinWidth: true,
};

export default NavbarBox;
