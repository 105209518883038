import React from "react";
// import { Link } from "gatsby";
import { RouteLink } from "../../components/RouteLink";
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { locales, useTranslation } from "../../core/hooks/useTranslation";
import NavbarBox from "./NavbarBox";
import links from "../../links";

import {
  AuthenticatedTemplate,
  useMsal,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { loginRequest } from "../../config/authConfig";

function Navbar({ onLogin }) {
  const { t, language, setLanguage } = useTranslation();
  const { instance } = useMsal();
  const { accounts } = useMsal();

  return (
    <Flex height="100%">
      {links.map((link, i) => (
        <RouteLink key={`header-nav-link-${i}-${link.text}`} to={link.link}>
          <NavbarBox>{t(link.text)}</NavbarBox>
        </RouteLink>
      ))}

      <NavbarBox>
        <Menu>
          <MenuButton
            fontSize="1rem"
            as={Button}
            paddingX="0"
            width="3.8rem"
            variant="ghost"
            background="transparent"
            _hover={{
              background: "transparent",
            }}
            _active={{
              background: "transparent",
            }}
            _focus={{
              background: "transparent",
            }}
          >
            <Flex alignItems="center" justifyContent="space-between">
              <Text>{locales[language]}</Text>
              <ChevronDownIcon />
            </Flex>
          </MenuButton>
          <MenuList background="dark.200" borderColor="dark.100">
            {Object.keys(locales).map((locale) => (
              <MenuItem
                key={`desktop-locale-${locale}`}
                onClick={() => setLanguage(locale)}
                background="transparent"
                // _hover={{
                //   background: "dark.300",
                // }}
                _active={{
                  background: "dark.300",
                }}
              >
                {locales[locale]}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </NavbarBox>
      <AuthenticatedTemplate>
        <NavbarBox
          onClick={() =>
            instance.logoutRedirect({ postLogoutRedirectUri: "/" })
          }
        >
          {t("link@sign_out")}
        </NavbarBox>
        {accounts ? (
          <RouteLink to="/funds">
            <NavbarBox filled={true}>{t("link@funds")}</NavbarBox>
          </RouteLink>
        ) : null}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <a href="#" onClick={onLogin}>
          <NavbarBox filled={true}>{t("link@login")}</NavbarBox>
        </a>
      </UnauthenticatedTemplate>
    </Flex>
  );
}

export default Navbar;
