import React from "react";
import TransitionLink from "gatsby-plugin-transition-link";
import { Link } from "gatsby";

function RouteLink({ to, children }) {
  if ("undefined" === typeof window) {
    return <Link to={to}>{children}</Link>;
  }

  return (
    <TransitionLink
      to={to}
      exit={{
        length: 0.4,
        state: {
          x: -window.innerWidth,
          opacity: 0,
        },
      }}
      entry={{
        delay: 0,
        state: {
          x: window.innerWidth,
        },
      }}
    >
      <motion>
      {children}
      </motion>
    </TransitionLink>
  );
}

export default RouteLink;
