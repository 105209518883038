import React, { useEffect, useMemo } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

function ViewportAnimation({ children, repeat, ...animationProps }) {
  const isMobile = useMemo(() => {
    if ("undefined" !== typeof window) {
      return window.innerWidth < 576;
    }

    return false;
  }, []);

  const controls = useAnimation();
  const { ref, inView } = useInView();

  useEffect(() => {
    if (isMobile) {
      return;
    }

    if (inView) {
      controls.start("stateAnimate");
    }

    if (repeat) {
      if (!inView) {
        controls.start("stateInitial");
      }
    }
  }, [isMobile, controls, inView, repeat]);

  if (isMobile) {
    return children;
  }

  const variants = {
    stateInitial: animationProps.initial,
    stateAnimate: animationProps.animate,
  };

  return (
    <motion.div
      ref={ref}
      variants={variants}
      initial="stateInitial"
      animate={controls}
      style={{
        ...(animationProps.style || {}),
        maxWidth: "100%",
      }}
      transition={animationProps.transition}
    >
      {children}
    </motion.div>
  );
}

ViewportAnimation.defaultProps = {
  repeat: false,
};

export default ViewportAnimation;
